
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Teko:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Warnock Pro';
  src:
  url('./assets/font/WarnockPro-SemiboldCapt.woff2') format('woff2'),
  url('./assets/font/WarnockPro-SemiboldCapt.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


body,
#root {
  font-family: 'Merriweather', serif;
  overflow-x: hidden;
}

body .btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #d12e2e;
  --bs-btn-border-color: #d12e2e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 11,172,204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d12e2e;
  --bs-btn-active-border-color: #d12e2e;
}
.searchIcon {
  width: 15px;
  margin-right: 5px;
  display: inline-block;
}
.searchIcon svg {
  width: 100%;
}
header {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./assets/bg.png');
  padding: 30px 0;
  border-bottom: 4px solid #d12e2e;
}
._logo_wrapper img {
  width: 320px
}
.searchInput{
  font-family: 'Merriweather', serif;
}
body .container {
  max-width: 1430px;
}
.home-sections {
  padding: 30px 0;
  margin-top: 50px;
  background-image: url('./assets/site-bg.jpg');
  background-repeat: repeat;
  background-position: center center;
}
.home-sections .title {
  display: inline-block;
  float: none;
  width: auto;
  margin: auto 0 30px 10px;
  font-family: 'Warnock Pro', sans-serif;
  position: relative;
  z-index: 2;
  font-weight: bolder;
}

.home-sections .title._set {
  color: #fff;
  background: #d12e2e;
  border-radius: 6px;
  padding: 5px 8px;
}

.home-sections .title._secondary:before {
    position: absolute;
    left: -100px;
    top: 14px;
    width: 70px;
    background: #d12e2e;
    height: 4px;
    content: "";
}
.home-sections .title._secondary:after {
    position: absolute;
    right: -100px;
    top: 14px;
    width: 70px;
    background: #d12e2e;
    height: 4px;
    content: "";
}


.userCardWrapper {
  position: relative;
}

.cardWrapper {
  padding: 15px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 260px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 4px rgba(63, 63, 63, 0.2);
  position: relative;
  margin-bottom: 2em;
}

.cardWrapper .image{ 
  position: absolute;
  left: -65px;
  top: 60px;
}
.cardWrapper .image img{
  height: 130px;
  width: 130px;
  object-fit: cover;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid rgba(63, 63, 63, 0.1);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 2px 2px 4px rgba(63, 63, 63, 0.2);
}
.cardWrapper .text{
  display: inline-block;
  padding-left: 70px;
  width: 100%;
}
.cardWrapper .text .publish-date {
  color: #3f3f3f;
  text-align: right;
  font-family: 'Merriweather', serif;
}
.cardWrapper .text .title {
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 0px;
  margin-left: 0;
}
.cardWrapper .text .title a {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.5s;
}
.cardWrapper .text a {
  color: #3f3f3f;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.5s;
}
.cardWrapper .text .sub-title {
  margin-bottom: 10px;
  color: #666;
  font-size: 0.85em;
}
.cardWrapper .text .summary {
  font-size: 0.85em;
  margin-bottom: 15px;
  color: #000;
}
.cardWrapper .text .authors {
  display: block;
  font-size: 0.95em;
}
.cardWrapper .text .authors a {
  color: #3f3f3f;
  display: block;
  font-weight: 600;
  font-family: 'Merriweather', serif;
}
.seeAll{
  font-size: 18px;
  color: #d12e2e;
  text-decoration: none;
  transition: all 0.5s;
  font-family: 'Merriweather', serif;
}

/* placeWrapper */ 

.placeWrapper {
  height: auto;
    overflow: hidden;
    margin-bottom: 30px;
}
.placeWrapper a {
  position: relative;
  height: auto;
  overflow: hidden;
  display: block;
  padding: 5px;
  border: 1px solid #ddd;
}
.placeWrapper a img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: top center;
  transition: all 0.5s;
  filter: sepia(0);
}
.placeWrapper a:hover img {
  filter: sepia(1);
}
.placeWrapper a .title {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  margin: 0;
  background: rgba(63, 63, 63, 0.7);
  color: #fff;
  padding: 7px;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  transition: all 0.5s;
}

.page_set {
  border-bottom: 1px solid rgba(63, 63, 63, 0.1);
}
.page_title {
  font-family: 'Warnock Pro', sans-serif;
}

.view_count {
  color: #6c757d;
  text-align: center;
}

._quick_title {
  font-family: 'Warnock Pro', sans-serif;
  color: #d12e2e;
  margin-bottom: 15px;
  font-size: 2em;
  font-weight: lighter;
  font-size: 1.3em;
  margin: 0 50px 0 0;
}

.page-content.contact a {
  color: #3f3f3f;
  text-decoration: none;
  transition: all 0.5s;
  font-family: 'Merriweather', serif;
}

.page-content.contact .contact-box h3 {
  color: #d12e2e;
  font-family: 'Warnock Pro', sans-serif;
  margin-bottom: 20px;
}

.page_title small {
  display: block;
  margin-top: 5px;
  font-size: 0.5em;
  color: rgba(63, 63, 63, 0.5);
  font-family: 'Merriweather', serif;
}

._icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}
svg {
  width: 20px;
  height: 20px;
  display: inline-block;
}
footer a {
  color: #3f3f3f;
  text-decoration: none;
  transition: all 0.5s;
}

footer ._links a{
  font-size: 18px;
  display: inline-flex;
  text-align: center;
  position: relative;
  padding: 0 .5em;
  margin: 0 .3em;
  font-family: 'Warnock Pro', sans-serif;
}
footer ._links a:hover{
  color: #d12e2e;
}
footer ._links a:not(:last-child):after {
  content: "|";
  display: inline-block;
  margin: 0 0 0 1em;
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

.items-list .card-columns {
  column-count: 4;
}
.items-list .card .card-title {
  font-size: 1em;
  font-weight: 600;
  color: #3f3f3f;
  margin-bottom: 0.75rem;
}
.items-list .card .card-subtitle {
  font-size: 0.85em;
  margin: 5px 0;
}
._footer_bottom {
    padding: 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top: 4px solid #3f3f3f;
    background: #cce2ea;
    background-size: 100% 100%;
    background-image: url('./assets/footer-bg.png');
}
footer .footer-line {
  background: #3f3f3f;
  color: #fff;
  font-size: 0.85em;
  padding: 7px 0;
  font-family: 'Warnock Pro', sans-serif;
}
footer .footer-line a {
  color: #fff;
  padding: 0 3px;
  font-family: 'Warnock Pro', sans-serif;
}

footer .footer-line .nanomedya img {
  height: 16px;
  width: auto;
  /* filter: brightness(0) invert(1);
  transition: all 0.5s; */
}
footer .footer-line .nanomedya img:hover {
  filter: brightness(1) invert(0);
}


.kvkk {
  position: fixed;
  max-width: 100%;
  width: 400px;
  height: auto;
  bottom: 30px;
  left: 30px;
  z-index: 70;
  background: rgba(63, 63, 63, 0.9);
  border: 3px solid #3f3f3f;
  color: #fff;
  padding: 30px;
  font-size: 0.75em;
}
.kvkk a {
  text-decoration: underline;
  color: #fff;
}
.kvkk .btn {
  font-size: 0.85em;
  padding: 5px 10px;
  outline: none;
  background: #fff;
  box-shadow: none;
  border-radius: 0;
  transition: all 0.5s;
}
.kvkk .btn:hover, .kvkk .btn:focus, .kvkk .btn:active {
  background: #289AB3;
  color: #fff;
}
.page-header {
  padding: 30px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(63, 63, 63, 0.1);
} 

#menuIconMain{
  border: none !important;
  border-color: none !important;
}
#menuIconMain svg {
  max-width: 25px;
}
body .dropdown-toggle#menuIconMain::after{
  display: none;
}
.page-text.author-detail {
  font-size: 0.85em;
  text-align: justify;
}
.page-text.author-detail h2 {
  font-size: 1.5em;
  color: #d12e2e;
}
.card-columns .card {
  display: inline-block;
  width: 100%;
}
/* reset */
.carousel-indicators {
  margin-bottom: 2.5em !important;
}
body .carousel-indicators button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px !important;
  border: none !important;
}
body .carousel-indicators button.active {
  background-color: #d12e2e !important;
}


.items-filter ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.items-filter ul li {
  float: none;
  display: inline-block;
  min-width: 30px;
  margin-right: 10px;
}
.items-filter ul li span {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  background: #3f3f3f;
  color: #fff;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 0.85em;
}
.items-filter ul li.active span {
  background: #d12e2e;
}

ul.category-list {
  column-count: 4;
  margin: 70px 0 0 0;
  padding: 0;
  list-style-type: none;
}
ul.category-list li {
  margin-bottom: 10px
}
ul.category-list li a {
  color: #3f3f3f;
  text-decoration: none;
  transition: all 0.5s;
}

.item-image {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 2px solid #d12e2e;
}
.caption {
  text-align: center;
  margin-top: 10px;
  color: #3f3f3f;
  font-size: 18px;
  font-family: 'Warnock Pro', sans-serif;
}
._content_wrapper {
  margin-top: 30px;
  text-align: justify;
  font-size: 1em;
  color: #212529;
  font-family: 'Merriweather', serif;
  border-right: 1px solid #ddd;
  padding-right: 1em;
}
.page-text #titlesArea {
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
  display: none;
}

.boxes {
  padding-bottom: 10px;
  border-bottom: 2px solid #d12e2e;
}

.boxes .box {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: 'Merriweather', serif;
}
.boxes .box a {
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  text-align: left;
}
.boxes .box .image {
  margin-right: 10px;
}
.boxes .box .image img {
  height: 120px;
  width: 95px;
  object-fit: contain;
  padding: 5px;
  border: 1px solid #ddd;
}
.boxes .box .description {
  font-size: 0.75em;
  color: #d12e2e;
}
.boxes .box .title {
  font-size: 0.85em;
  font-weight: 600;
  color: #3f3f3f;
  transition: all 0.5s;
}
.boxes .box .abstract {
  font-size: 0.85em;
  margin-bottom: 7px;
  color: #3f3f3f;
}
.boxes .box .icons {
  display: flex;
  align-items: center;
  justify-content: left;
  color: #3f3f3f;
  font-size: 0.85em;
}
.boxes .box .icons ._icon,
.boxes .box .icons ._icon svg{
  width: 13px;
  margin-right: 5px;
}
.related-items a {
  color: #d12e2e;
  text-decoration: none;
  display: block;
}

.social_links a {
  color: #3f3f3f;
  transition: all 0.5s;
  text-decoration: none;
  display: inline-block;
  margin: 0 5px;
}
ul.references {
  position: relative;
  list-style: none;
  padding: 0;
}
ul.references li {
  margin-bottom: 7px;
  list-style: none;
  font-size: 0.8em;
}
.page-buttons .top {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  position: relative;
}

.page-buttons .top .left {
  padding: 10px;
  display: flex;
  align-items: center;
}
.page-buttons .top .left a {
  color: #3f3f3f;
  text-decoration: none;
  transition: all 0.5s;
}
.page-buttons .top .right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.page-buttons .top .right span {
  font-size: 1em;
  padding: 15px;
  border-left: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.5s;
  color: #666;
}


@media only screen and (max-width: 1024px){
.cardWrapper {
    display: block!important;
    height: auto;
    overflow: hidden;
    padding: 0!important;
    font-size: 0.75em;
    max-height: 300px;
}

.cardWrapper .image {
    display: block;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    overflow: hidden;
    margin-bottom: 15px;
}
.cardWrapper .image img {
  padding: 0;
  box-shadow: none;
  border: 0;
  width: 100%;
  height: 100px;
  border-radius: 0;
  object-fit: cover;
}

.cardWrapper .text {
  width: 100%;
  padding: 5px;
}

.cardWrapper .text .publish-date {
  text-align: left;
}


.cardWrapper .text .authors {
  display: none;
}


.carousel-control-next, .carousel-control-prev {
  opacity: 1 !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background: #d12e2e;
  filter: none !important;
}




footer ._links a{
  font-size: 14px;
}

._logo_wrapper {
  display: inline-block;
}
._logo_wrapper img {
  width: 260px;
}
body .searchInput {
  font-size: 14px;
}
.searchIcon {
  display: none;
}
body .mainCarousel .carousel-indicators {
  bottom: -30px;
  margin-bottom: 0 !important;
}
body .mainCarousel .carousel-control-next, 
body .mainCarousel .carousel-control-prev {
  top: unset;
  bottom: -40px;
}
body .carousel-control-next-icon, 
body .carousel-control-prev-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px;
}
.home-sections h2.title{
  font-size: 1.2em;
}
footer ._links a:not(:last-child):after {
  display: none;
}

.items-filter ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.items-filter ul li {
  display: block;
  min-width: unset;
  flex-grow: 1;
  width: 17%;
  margin-right: 3px;
}
.items-filter ul li span {
  min-width: unset;
  height: 30px;
  line-height: 30px;
  margin-bottom: 3px;
}
ul.category-list {
  column-count: 1;
}
.item-gallery .box {
  margin-bottom: 30px;
}
.item-gallery .box .image img {
  width: 100%;
  height: auto;
}
.item-gallery .box .caption {
  font-size: 0.85em;
  text-align: center;
  margin: 0;
  font-family: 'Merriweather', serif;
}
header {
  padding: 10px 0;
  border: none;
}

.home-sections .title._set {
  padding: 9px 10px;
}

.view_count {
  font-size: 14px;
}
._content_wrapper {
  border-right: none;
  padding: 0;
}

.page-text .page_title {
  margin-bottom: 10px;
  font-size: 2em;
}
.page-text .item-image img {
  width: 100%;
  height: auto;
}

.related-items h3 {
  color: #d12e2e;
  margin-bottom: 30px;
}
.item-gallery img {
  width: 100%;
  height: auto;
}

}


.kvkk {
  max-width: 90%;
  width: 100%;
  margin: auto;
  left:0;
  right: 0;
}



.customizer {
  width: 60px;
  height: auto;
  padding: 7px;
  border: 1px solid #ddd;
  position: absolute;
  left: -70px;
  top: 93px;
}
.customizer .top {
  margin-bottom: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.customizer .top span {
  cursor: pointer;
  transition: all 0.5s;
} 
.customizer .bottom {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  align-items: end;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.customizer .colors {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.customizer .close-customizer {
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 0.75em;
  text-align: center;
  cursor: pointer;
}
.customizer .colors span {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #ddd; 
  cursor: pointer;
}
.customizer .colors span:last-child {
  background: antiquewhite;
}

.icon-container:hover ._icon svg path {

  fill: #d12e2e;
}


.items-filter ul li a {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  background: #848484;
  color: #fff;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 0.9em;
  text-decoration: none;
  padding: 2px;
}

.items-filter ul li a.active {
  background: #289AB3;
  text-decoration: none;
}
#sections * {
  font-size: inherit !important;
}
.cursor-pointer {

  cursor: pointer;

}